import React, { useEffect, useRef } from 'react';
import styles from './Search.module.scss';
import { Button } from '../../../base-components/Button.js';

import type { SearchProps } from './Search.type.js';
import { SearchIcon } from '@/components/Icons/index.js';

export const Search: React.FC<SearchProps> = (props) => {
  const { placeholder, value, handleInput, handleSubmit } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handlePressEnter = (e: { keyCode: number }) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    };
    if (inputRef && inputRef.current) {
      const current = inputRef.current;
      current?.addEventListener('keydown', handlePressEnter, false);
      return function cleanup() {
        current?.removeEventListener('keydown', handlePressEnter, false);
      };
    }
  }, [handleSubmit]);

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <input
          name="search"
          value={value}
          className={styles.input}
          placeholder={placeholder}
          onChange={(e) => handleInput(e.currentTarget.value)}
          ref={inputRef}
        />
        <Button onClick={handleSubmit} className={styles.button}>
          <SearchIcon />
          Поиск
        </Button>
      </div>
    </div>
  );
};
