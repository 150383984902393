import { FC, useCallback, useEffect, useState } from 'react';
import React from 'react';
import styles from './Main.module.scss';
import { MainPageProps } from '../../pages/index.js';
import { Search } from './Search/index.js';
import { Col } from '../Purchases/components/Col.js';
import { Row } from '../Purchases/components/Row.js';
import { Link } from '@/npm/next.js';
import { rootLinks } from '../Links.js';
import { RigthArrowIcon } from '../Icons/index.js';
import { Purchases } from './Purchases/index.js';
import Loader from '../Purchases/components/Loader.js';

export type MainProps = MainPageProps;

export const Main: FC<MainProps> = ({ announces }) => {
  return (
    <div className={styles.stub}>
      <Loader />
    </div>
  );
  // console.log('announces', announces);
  const isBrowser = typeof window !== 'undefined';
  const [windowWidth, setWindowWidth] = useState(
    isBrowser ? window.innerWidth : 1120,
  );

  const handleResize = useCallback(() => {
    setWindowWidth(isBrowser ? window.innerWidth : 1120);
  }, [isBrowser]);
  useEffect(() => {
    return window.addEventListener('resize', handleResize);
  }, [handleResize]);
  return (
    <div className={styles.container}>
      <div className={styles.bg}>
        <div className={styles.wrapper}>
          <h1>
            3000 закупок ежегодно
            <br />
            Ваша площадка <strong>выгодных</strong> контрактов
          </h1>
          <div className={styles.search}>
            <p>Начинайте работать прямо сейчас</p>
            <Search placeholder="Введите номер или наименование закупки, ИНН, название заказчика" />
          </div>
        </div>
      </div>
      <div className={styles.purchases}>
        <div className={styles.wrapper}>
          <h3>Успейте взять выгодный контракт!</h3>
          <Purchases announces={announces} />
          <Row className={styles.action}>
            <Link href={rootLinks.purchases.url}>
              Все тендеры
              <RigthArrowIcon />
            </Link>
          </Row>
        </div>
      </div>
      <div className={styles.words}>
        <Col className={styles.wrapper}>
          <h3>Уважаемые партнёры</h3>
          <p>
            Департамент внутреннего контроля ООО «Сэтл Групп» информирует: если
            Вам стало извостно о нарушениях регламентов проведения тендерных
            процедур или процедуры аккредитации, а также о злоупотреблениях
            коррупционного характера, просим сообщать об этом в Департамент
            внутреннего контроля для своевременного реагирования и принятия мер.
          </p>
          <p>
            Информацию можно направлять на почту dvk@рn.ru или по телефону: 8
            (812)-335-13-10
          </p>
        </Col>
      </div>
    </div>
  );
};
