import clsx from 'clsx';
import { ReactNode } from 'react';
import React from 'react';
import styles from './Row.module.scss';

interface Props {
  className?: string;
  children?: ReactNode;
}

export const Row: React.FC<Props> = ({ className, children }) => (
  <div className={clsx(styles.row, className)}>{children}</div>
);
