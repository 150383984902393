import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import type { NextPage } from 'next';
import { cachedFetchMainData } from '../api/fetchMainData.js';
import { useResetBreadcrumbs } from '../components/_shared/Breadcrumbs/BreadcrumbsContext.js';
import { Main } from '../components/Main/index.js';
import type { Services } from '../types.js';

export type MainPageProps = {
  announces: Awaited<ReturnType<typeof cachedFetchMainData>>['announces'];
};

const MainPage: NextPage<MainPageProps> = ({ announces }) => {
  useResetBreadcrumbs();
  return <Main announces={announces} />;
};

export default MainPage;

export const getServerSideProps = async (
  context: GetServerSidePropsContext & {
    req: { services: Services };
  },
) => {
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59',
  );

  const mainData = await cachedFetchMainData(context.req.services);

  return {
    props: {
      announces: mainData.announces,
    },
  };
};
