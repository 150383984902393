import React, { useMemo } from 'react';
import { Row } from '../../Purchases/components/Row.js';
import type { Purchase } from '../../../types/entities.js';
import styles from './Purchases.module.scss';
import { PurchaseItem } from './index.js';

type Props = {
  announces: Purchase[];
};

export const Purchases: React.FC<Props> = ({ announces }) => {
  // console.log('announces', announces);
  const filteredAnnounces = useMemo(() => {
    // Это костыль, и фильтрация должна идти на уровне бэка, но почему-то не идёт на бою
    return announces
      .filter((announce) => {
        return (
          !announce.isTest &&
          !announce.isArchived &&
          !announce.isTemplate &&
          !announce.deletedDate &&
          !!announce.title
        );
      })
      .slice(0, 6)
      .filter(Boolean);
  }, [announces]);
  return (
    <Row className={styles.container}>
      {filteredAnnounces.map((announce) => {
        return <PurchaseItem key={announce.id} announce={announce} />;
      })}
    </Row>
  );
};
