import React from 'react';
import { Col } from '../../Purchases/components/Col.js';
import type { Purchase } from '../../../types/entities.js';
import styles from './Purchases.module.scss';
import Phase from '../../Purchases/components/Phase.js';
import Title from '../../Purchases/components/Title.js';
import reject from 'lodash/reject.js';
import find from 'lodash/find.js';

type Props = {
  announce: Purchase;
};
export const PurchaseItem: React.FC<Props> = ({ announce }) => {
  const phases = reject(announce.phases, ['block.type.name', 'dealing']);
  const currentPhase = find(phases, ['id', announce.phaseId]);
  const isAnnounceCanceled = announce.status?.name === 'canceled';
  return (
    <Col className={styles.item}>
      <Title title={announce.title} needTooltip />
      <Phase phase={currentPhase} isAnnounceCanceled={isAnnounceCanceled} />
    </Col>
  );
};
