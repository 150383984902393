import { flow } from 'lodash';

import { Search } from './Search.js';
import { withState } from './Search.withState.js';

export const SearchContainer = flow(
    //
    () => Search,
    withState()
)();
